<template>
    <div>
        <page-title :heading="$t('accounting.lang_invoices')" :subheading="$t('accounting.lang_searchInvoicesSubheading')" :icon=icon></page-title>
        <div class="app-main__inner">
                        <SearchInvoice></SearchInvoice>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import SearchInvoice from "../../components/accounting/SearchInvoice";

    export default {
        components: {
            PageTitle,
            SearchInvoice
        },

        data: () => ({
            icon: 'pe-7s-search icon-gradient bg-tempting-azure',

        }),
    }
</script>